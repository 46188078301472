import React from "react"
import Img from 'gatsby-image'

import headerStyles from "./header.module.css"

export default props => (
    <header className={headerStyles.container}>
          <Img className={headerStyles.picture} fluid={props.fluidImage} />
          <div className={headerStyles.advert}><span>Welcome a new member of</span><span>&nbsp;your&nbsp;family</span></div>
        <div className={headerStyles.verticalLine} />
    </header>
)