import React from "react"
import Img from 'gatsby-image'

import footerStyles from "./footer.module.css"

export default class Footer extends React.Component {
  state={
    'email': '',
    'sent': false,
    'info': ''
  }
  handleInputChange = event => {
    this.setState({'email': event.target.value})
  }
  handleSubmit = event => {
    event.preventDefault()
    let data = new FormData();
    data.append('email', this.state.email)
    fetch('https://walle.co/newsletter.php', {
      method: 'POST',
      body: data
    })
      .then(res => res.text())
      .then(resp => {
        this.setState({'sent': true, 'info': resp})
      })
      .catch(error => console.error(error))
  }
  render() {
    return (
      <div className={footerStyles.container}>
        <div className={footerStyles.heading}>JOIN OUR NEWSLETTER</div>
        <div className={footerStyles.subscription}>Sign up to receive fresh stories and notifications about major updates.</div>
        <div className={footerStyles.formInfo}><span className={(this.state.sent) ? 'fadeIn' : ''}>{this.state.info}</span></div>
        <form onSubmit={this.handleSubmit}>
          <div>
            <input className={footerStyles.input}
                   type='email'
                   name='email'
                   onChange={this.handleInputChange}
                   value={this.state.email}
                   placeholder='Enter your email' />
          </div>
          <div>
            <input className={footerStyles.button}
                   type='submit'
                   value='Subscribe'/>
          </div>
        </form>
        <div className={footerStyles.light}>We are part of the &emsp;<Img className={footerStyles.logo} fluid={this.props.omgkrk} /></div>
        <div className={footerStyles.credits}>&copy;2019 Walle. All rights reserved.</div>
      </div>
    )
  }
}

// export default this.props => (
//
// )