import React from "react"
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

import automationStyles from "./automation.module.css"

export default props => (
    <BackgroundImage fluid={props.room}>
      <div className={automationStyles.container}>
        <Img className={automationStyles.bulb} fluid={props.bulb} />
        <div className={automationStyles.heading}>Stay smart, stay wise</div>
        <div className={automationStyles.description}>Walle is also a platform for managing your home automation. With wireless controllers you can effortlessly control central heating and adjust the temperature in a friendly way. Thanks to better energy management you save money.</div>
      </div>
  </BackgroundImage>
)
