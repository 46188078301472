import React from "react"
import Img from 'gatsby-image'

import deviceStyles from "./device.module.css"

export default props => (
  <div className={deviceStyles.container}>
    <div className={deviceStyles.header}>Walle. Designed to be glimpsed</div>
    <div className={deviceStyles.mockups}>
      <div id='mock1'><Img className={deviceStyles.image} fluid={props.mock1} /></div>
      <div id='desc1' className={deviceStyles.description}>
        <div className={deviceStyles.headingFirst}>Move into the future</div>
        <div className={deviceStyles.content}>Walle replaces the static paper wall calendar and provides even more useful real-time information.
          It is a digital home information center which enables you to live simply.</div>
        <div className={deviceStyles.headingSecond}>Look, read, know</div>
        <div className={deviceStyles.content}>Glance over at the weather forecast when you're leaving the house. Read breaking news and be
          up-to-date with the world. Get to know the next bus departure time. Everything without any
          interaction with the screen.</div>
      </div>
      <div id="mock2"><Img className={deviceStyles.image} fluid={props.mock2} /></div>
      <div id='desc2' className={deviceStyles.description}>
        <div className={deviceStyles.headingFirst}>Family assistant at home</div>
        <div className={deviceStyles.content}>You can plan family time based on calendar events and get instant reminders about the important
          ones. Walle helps you to be organized on a daily basis. Make to-do lists and share them with other
          family members.</div>
        <div className={deviceStyles.headingSecond}> Walle, turn the lights on</div>
        <div className={deviceStyles.content}>Built-in voice service becomes your home assistant. Tell Walle to control smart home devices so you
          don't have to waste time pulling the phone out of your pocket.</div>
      </div>
    </div>
  </div>
)