import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/Header";
import Device from "../components/Device";
import Automation from "../components/Automation";
import Footer from "../components/Footer";

export default ({data}) => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Walle Homepage</title>
      <link rel="canonical" href="https://walle.co/new" />
    </Helmet>
    <Header fluidImage={data.logo.childImageSharp.fluid} />
    <Device mock1={data.mock1.childImageSharp.fluid} mock2={data.mock2.childImageSharp.fluid} />
    <Automation room={data.room.childImageSharp.fluid} bulb={data.bulb.childImageSharp.fluid}/>
    <Footer omgkrk={data.omgkrk.childImageSharp.fluid} />
  </Layout>
);

export const pageQuery = graphql`
  query {
    logo: file(relativePath: { eq: "Logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mock1: file(relativePath: { eq: "Mockup1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mock2: file(relativePath: { eq: "Mockup2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bulb: file(relativePath: { eq: "bulb.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    room: file(relativePath: { eq: "room.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    omgkrk: file(relativePath: { eq: "omgkrk.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;